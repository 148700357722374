import api from "@/libs/axios";
import axios from "axios";

const userFinancial = {
  state: {
    depositsList: [],
    depositsListPix: [],
    depositsListPixApproveReject: [],
    withdrawListPixApproveReject: [],
    cryptoWithdrawalList: [],
    cryptoDepositsList: [],
    withdrawsList: [],
    withdrawsListPIX: [],
    transfersList: [],
    transfersReports: [],
    exchangeRate: 5,
    individualCryptoDepositsList: [],
    individualCryptoWithdrawalList: [],
  },
  getters: {
    returnExchangeRate: (state) => state.exchangeRate,
    returnDepositsList: (state) => state.depositsList,
    returnDepositsListPix: (state) => state.depositsListPix,
    returnDepositsListPixApproveReject: (state) =>
      state.depositsListPixApproveReject,
    returnWithdrawListPixApproveReject: (state) =>
      state.withdrawListPixApproveReject,
    returnWithdrawsList: (state) => state.withdrawsList,
    returnWithdrawsListPIX: (state) => state.withdrawsListPIX,
    returnTransfersList: (state) => state.transfersList,
    returnCryptoWithdrawalList: (state) => state.cryptoWithdrawalList,
    returnCryptoDepositsList: (state) => state.cryptoDepositsList,
    returnTransfersReports: (state) => state.transfersReports,
    returnIndividualCryptoWithdrawalList: (state) => state.individualCryptoWithdrawalList,
    returnIndividualCryptoDepositsList: (state) => state.individualCryptoDepositsList,
  },
  actions: {
    withdrawRequest: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post("/withdraw", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    userCreateDeposit: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post("/deposit", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    userCreateDepositPIX: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post("/deposit-pix", data)
          .then(async (resp) => {
            resolve(resp.data);
          })
          .catch((err) => {
            reject(err.response.data);
          });
        // let proofPix = new FormData();
        // proofPix.append("proofPix", data.proof);
        // await api
        //   .post("/upload-proof-pix", proofPix)
        //   .then(async (response) => {
        //     data.proof = response.data.proofPix;
        //     await api
        //       .post("/deposit-pix", data)
        //       .then(async (resp) => {
        //         resolve(resp.data);
        //       })
        //       .catch((err) => {
        //         reject(err.response.data);
        //       });
        //   })
        //   .catch((error) => {
        //     reject(error.response.data);
        //   });
      });
    },
    userDepositsList: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }
        await api
          .get(
            `/list-deposits?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addUserDepositsList", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    userDepositsListPix: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }
        await api
          .get(
            `/list-deposits-pix?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addUserDepositsListPix", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    userDepositsListPixApproveReject: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }
        await api
          .get(
            `/admin/list-deposits-pix-approve-reject?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addUserDepositsListPixApproveReject", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    userWithdrawListPixApproveReject: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }
        await api
          .get(
            `/admin/list-withdraw-pix-approve-reject?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addUserWithdrawListPixApproveReject", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    userCryptoWithdrawalList: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "100";
        }
        await api
          .get(
            `/admin/crypto-withdrawal-list?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addUserCryptoWithdrawalList", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    userCryptoDepositsList: ({ commit }, params) => {

      return new Promise(async (resolve, reject) => {
        let outhersParams = "";

        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.startDate != "" && params.startDate != null) {
          outhersParams = outhersParams + `&startDate=${params.startDate}`;
        }
        if (params.endDate != "" && params.endDate != null) {
          outhersParams = outhersParams + `&endDate=${params.endDate}`;
        }
        if (params.selectedProduct != null) {
          outhersParams = outhersParams + `&selectedProduct=${params.selectedProduct.value}`;
        }
        if (params.selectedMethod != null) {
          outhersParams = outhersParams + `&selectedMethod=${params.selectedMethod.value}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }

        await api
          .get(
            `/admin/crypto-deposits-list?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addUserCryptoDepositsList", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    userTransfersReports: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }
        await api
          .get(
            `/admin/transfers-reports?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addUserTransfersReports", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    depositPixApproveReject: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch(`/admin/deposits-pix-approve-reject`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    withdrawPixApproveReject: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch(`/admin/withdraw-pix-approve-reject`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    userWithdrawsList: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }
        await api
          .get(
            `/list-withdraws?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addUserWithdrawsList", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    userWithdrawsListPIX: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }
        await api
          .get(
            `/list-withdraws-pix?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addUserWithdrawsListPIX", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    withdrawsApproveReject: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch(`/admin/crypto-withdrawal-approve-reject`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    searchUser: ({ commit }, search) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/search-user/${search}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    userCreateTransfer: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post("/transfer", data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    userTransfersList: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }
        await api
          .get(
            `/transfers-list?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addUserTransfersList", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    convertUSDtoURL: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        await axios
          .get(`https://economia.awesomeapi.com.br/json/last/USD-BRL`)
          .then((response) => {
            resolve(response.data.USDBRL);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    userLogsList: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let queryParams = new URLSearchParams();
        queryParams.append('page', params.page);
        queryParams.append('limit', params.limit);
        queryParams.append('sortby', params.sortby || 'createdAt');
        queryParams.append('sortdirection', params.sortdirection || 'desc');

        if (params.startDate) {
          queryParams.append('startDate', params.startDate);
        }
        if (params.endDate) {
          queryParams.append('endDate', params.endDate);
        }
        if (params.category != null && params.category != '') {
          queryParams.append('category', params.category);
        }
        // Adicione outros parâmetros conforme necessário

        await api
          .get(`/admin/user-logs-list/${params.idUser}?${queryParams.toString()}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },

    individualCryptoDepositsList: ({ commit }, params) => {

      return new Promise(async (resolve, reject) => {
        let outhersParams = "";

        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.startDate != "" && params.startDate != null) {
          outhersParams = outhersParams + `&startDate=${params.startDate}`;
        }
        if (params.endDate != "" && params.endDate != null) {
          outhersParams = outhersParams + `&endDate=${params.endDate}`;
        }
        if (params.selectedProduct != null) {
          outhersParams = outhersParams + `&selectedProduct=${params.selectedProduct.value}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }

        await api
          .get(
            `/finance/individual-crypto-deposits-list?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addUserIndividualDepositsList", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    individualCryptoWithdrawalList: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {


        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.startDate != "" && params.startDate != null) {
          outhersParams = outhersParams + `&startDate=${params.startDate}`;
        }
        if (params.endDate != "" && params.endDate != null) {
          outhersParams = outhersParams + `&endDate=${params.endDate}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }
        await api
          .get(
            `/financial/individual-crypto-withdrawal-list?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addIndividualCryptoWithdrawalList", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },

    // WEB3
    readWalletBalances: ({ commit }, blockchain_address) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/web3/readWalletBalances/${blockchain_address}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },

    transferToken: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post(`/web3/transferToken`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },

    swapTokens: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post(`/web3/swapTokens`, data)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },

    historic_wallet: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/web3/historic_wallet/${data}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
  },
  mutations: {
    addUserDepositsList: (state, list) => {
      state.depositsList = list;
    },
    addUserDepositsListPix: (state, list) => {
      state.depositsListPix = list;
    },
    addUserDepositsListPixApproveReject: (state, list) => {
      state.depositsListPixApproveReject = list;
    },
    addUserWithdrawListPixApproveReject: (state, list) => {
      state.withdrawListPixApproveReject = list;
    },
    addUserCryptoWithdrawalList: (state, list) => {
      state.cryptoWithdrawalList = list;
    },
    addUserCryptoDepositsList: (state, list) => {
      state.cryptoDepositsList = list;
    },
    addUserTransfersReports: (state, list) => {
      state.transfersReports = list;
    },
    addUserWithdrawsList: (state, list) => {
      state.withdrawsList = list;
    },
    addUserWithdrawsListPIX: (state, list) => {
      state.withdrawsListPIX = list;
    },
    addUserTransfersList: (state, list) => {
      state.transfersList = list;
    },
    CHANGE_EXCHANGE_RATE: (state, rate) => {
      state.exchangeRate = rate;
    },
    addUserIndividualDepositsList: (state, list) => {
      state.individualCryptoDepositsList = list;
    },
    addIndividualCryptoWithdrawalList: (state, list) => {
      state.individualCryptoWithdrawalList = list;
    },
  },
};

export default userFinancial;
