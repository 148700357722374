import axios from 'axios'
import Cookie from 'js-cookie';

let api = process.env.VUE_APP_API_URL

const axiosIns = axios.create({
  baseURL: api
})

axiosIns.interceptors.request.use((config) => {
  const token = Cookie.get('newxcoinsToken')
  let locale = localStorage.getItem('newxcoins-locale')

  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }

  if (locale) {
    if (locale == 'pt')
      locale = 'pt-br'
    config.headers.locale = locale
  }

  return config
}, (err) => {
  return Promise.reject(err)
})

axiosIns.interceptors.response.use((response) => {
  return response
}, (error) => {
  // if (error.response.status === 401) {
  //   window.location = '/login'
  // }

  return Promise.reject(error)
})

export default axiosIns

