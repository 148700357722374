import api from "@/libs/axios";

const administrator = {
  state: {
    usersList: [],
    individualUserData: [],
    balanceManagerData: [],
    graduationsList: [],
    allCryptoDepositsList: []
  },
  getters: {
    returnUsersList: (state) => state.usersList,
    returnIndividualUser: (state) => state.individualUserData,
    returnBalanceManager: (state) => state.balanceManagerData,
    returnGraduationsList: (state) => state.graduationsList,
    returnAllCryptoDepositsList: (state) => state.allCryptoDepositsList,
  },
  actions: {
    allCryptoDepositsList: ({ commit }, params) => {
    
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.startDate != "" && params.startDate != null) {
          outhersParams = outhersParams + `&startDate=${params.startDate}`;
        }
        if (params.endDate != "" && params.endDate != null) {
          outhersParams = outhersParams + `&endDate=${params.endDate}`;
        }
        if (params.selectedProduct != null) {
          outhersParams = outhersParams + `&selectedProduct=${params.selectedProduct.value}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }
    
        await api
          .get(
            `/admin/all-crypto-deposits-list?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addUserAllDepositsList", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    }, 
    fetchAdminBonusHistorical({ commit }, params) {
      return new Promise(async (resolve, reject) => {
        const { startDate, endDate, bonusType, limit, search } = params || {};
        let query = '';
        if (startDate) query += `&startDate=${startDate}`;
        if (endDate) query += `&endDate=${endDate}`;
        if (bonusType) query += `&bonusType=${bonusType}`;
        if (limit) query += `&limit=${limit}`
        await api.get(`/admin/bonus-report?${query}`).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    fetchReportBinary({ commit }) {
      return new Promise(async (resolve, reject) => {
        await api.get(`/admin/binary-lost-points-report`).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
      })
    },
    usersList: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.status != "" && params.status != null) {
          outhersParams = outhersParams + `&status=${params.status}`;
        }
        if (params.function != "" && params.function != null) {
          outhersParams = outhersParams + `&function=${params.function}`;
        }
        if (params.sortby == null) {
          params.sortby = "created_at";
        }
        if (params.sortdirection == null) {
          params.sortdirection = "desc";
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }
        await api
          .get(
            `/admin/users-list/?page=${params.page}&limit=${params.limit}&sortby=${params.sortby}&sortdirection=${params.sortdirection}${outhersParams}`
          )
          .then((response) => {
            commit("addUsersList", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    addNewUser: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        function removeAccents(str) {
          const with_accent =
            "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";

          const no_accent =
            "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";

          let newStr = "";

          for (let i = 0; i < str.length; i++) {
            let replacement = false;
            for (let a = 0; a < with_accent.length; a++) {
              if (str.substr(i, 1) == with_accent.substr(a, 1)) {
                newStr += no_accent.substr(a, 1);
                replacement = true;
                break;
              }
            }
            if (replacement == false) {
              newStr += str.substr(i, 1);
            }
          }
          return newStr;
        }

        function getRandomArbitrary(min, max) {
          return Math.random() * (max - min) + min;
        }

        let fullName = removeAccents(data.fullName);
        fullName = fullName.replace(/[^a-zA-Zs]/g, "").toLowerCase();
        let randomNumber = getRandomArbitrary(1, 99999);
        fullName = fullName.substring(0, 13) + Math.floor(randomNumber);
        data.username = fullName;

        await api
          .post("/admin/user", data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    },
    sendEmailAPI: ({ commit }, id) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post(`/admin/sendemail/creation/user/${id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.errors);
          });
      });
    },
    disableUserAPI: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .patch(`/admin/user/${data.id}/edit`, {
            status: data.status,
            reason: data.description,
          })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    fetchUser: ({ commit }, id) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/admin/user/${id}`)
          .then((response) => {
            commit("addIndividualUser", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    fetchBalanceManager: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "100";
        }

        await api
          .get(
            `/admin/balance-manager?page=${params.page}&limit=${params.limit}${outhersParams}`
          )
          .then((response) => {
            commit("addBalanceManager", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    fetchGraduations: ({ commit }, params) => {
      return new Promise(async (resolve, reject) => {
        let outhersParams = "";
        if (params.search != "" && params.search != null) {
          outhersParams = outhersParams + `&search=${params.search}`;
        }
        if (params.page == null) {
          params.page = "1";
        }
        if (params.limit == null) {
          params.limit = "10";
        }

        await api
          .get(
            `/admin/graduations?page=${params.page}&limit=${params.limit}${outhersParams}`
          )
          .then((response) => {
            commit("addGraduations", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    addRemoveBalance: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        await api
          .post(`/admin/balance-manager/add-remove-balance`, data)
          .then((response) => {
            resolve(response.data.message);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    getUserLicense: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/get-user-licenses`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    adGetUserLicense: ({ commit }, idUser) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/admin/balance-manager/get-user-licenses/${idUser}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response);
          });
      });
    },
    editUser: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        if (data.avatar) {
          if (data.avatar !== "remove") {
            let avatar = new FormData();
            avatar.append("avatar", data.avatar);
            await api.post(`/admin/user/${data.id}/upload-image`, avatar);
            resolve("");
          } else {
            await api.delete(`/admin/user/${data.id}/avatar`);
            resolve("");
          }
        }

        if (data.userInfo) {
          await api
            .patch(`/admin/user/${data.id}/edit`, data.userInfo)
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error.response.data);
            });
        } else {
          reject("");
        }
      });
    },
    addUserRole: ({ commit }, data) => {
      return new Promise(async (resolve, reject) => {
        if (data.method == "add") {
          await api
            .patch(`/roles/${data.id_user}/add-role`, { roles: [data.id_role] })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error.response.data.errors);
            });
        } else {
          await api
            .post(`/roles/${data.id_user}/remove-role`, {
              roles: ["62a938f7ead20c947e7aa61c"],
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error.response.data.errors);
            });
        }
      });
    },
    fetchRolesList: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get("/admin/roles")
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    dashboardAdmin: ({ commit }, selectedLeg) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/admin/general-view`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    userFinancialSummary: ({ commit }, idUser) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/admin/user-financial-summary/${idUser}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      })
    }
  },
  mutations: {
    addUsersList: (state, users) => {
      state.usersList = users;
    },
    addIndividualUser: (state, user) => {
      state.individualUserData = user;
    },
    addBalanceManager: (state, balanceList) => {
      state.balanceManagerData = balanceList;
    },
    addGraduations: (state, graduationsList) => {
      state.graduationsList = graduationsList;
    },
    addUserAllDepositsList: (state, list) => {
      state.allCryptoDepositsList = list;
    }, 
  },
};

export default administrator;
