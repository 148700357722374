import api from "@/libs/axios";

const dashboard = {
  state: {
    analyticsData: [],
  },
  getters: {},
  actions: {
    dashboardAnalytics: ({ commit }, robot_id) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get(`/dashboard/analytics/${robot_id}`)
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    dashboardMyResults: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get("/dashboard/my-results")
          .then((response) => {
            resolve(response.data.myResults);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
    dashboardMyResultsWithoutOperation: ({ commit }) => {
      return new Promise(async (resolve, reject) => {
        await api
          .get("/dashboard/my-results/without-operation")
          .then((response) => {
            resolve(response.data.myResults);
          })
          .catch((error) => {
            reject(error.response.data.message);
          });
      });
    },
  },
  mutations: {},
};

export default dashboard;
