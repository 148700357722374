import api from "@/libs/axios";

const trading = {
    state: {},
    getters: {},
    actions: {
        getOrdersHistory: ({ commit }, params) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const response = await api.get(`/trader/orders-history/${params.robotId}?page=${params.page}&limit=${params.limit}`);
                    if (response && response.data) {
                        resolve(response.data);
                    } else {
                        reject(new Error('Invalid response format'));
                    }
                } catch (error) {
                    console.error('API Error:', error);
                    reject(error.response ? error.response.data : error);
                }
            });
        },
        allocateValueInTrading: ({ commit }, data) => {            
            return new Promise(async (resolve, reject) => {
                await api.post('/trader/allocate-value-in-trading', data).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        relocateValue: ({ commit }, data) => {            
            return new Promise(async (resolve, reject) => {
                await api.post('/trader/relocate-value', data).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        withdrawValueTrading: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                await api.post('/trader/robot-withdraw', data).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        changeConfigRobot: ({ commit }, config) => {
            return new Promise(async (resolve, reject) => {
                await api.patch('/trader/config-robot-trader', config).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        searchConfigRobot: ({ commit }) => {
            return new Promise(async (resolve, reject) => {
                await api.get('/trader/search-config-robot-trader').then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        signPlanRequest: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                await api.post('/trader/create-deposit-intent', { licenseValue: data.value, product: data.product, isHighRisk: data.isHighRisk }).then(response => {
                    resolve(response.data)
                }).catch(error => {
                    reject(error.response.data)
                })
            })
        },
        getDepositIntent: ({ commit }, data) => {
            return new Promise(async (resolve, reject) => {
                let product = '';

                if (data.planId) {
                    product = 'liquidity_for_pools';
                    data.value = 0;
                } else {
                    product = 'license';
                }

                let url = `/trader/get-deposit-intent/${product}/${data.value}`;

                if (data.planId) {
                    url += `/${data.planId}`;
                }

                await api.get(url).then(response => {
                    resolve(response.data);
                }).catch(error => {
                    reject(error.response.data);
                });
            });
        }
    },
    mutations: {},
}

export default trading;